<template>
  <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
    <div class="col-md-6 mx-auto">
      <div class="card rounded shadow-lg">
        <div class="card-body">
          <div class="text-center">
            <img src="/images/siged2.png" alt="homepage" class="dark-logo" />
          </div>
          <div class="card-title text-center">
            <h2>{{ title }}</h2>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row text-center">
                <label class="col-md-12 col-form-label">El token de recuperación de contraseña es inválido o ha expirado.</label>
              </div>
            </div>
          </div>           
          <div class="row text-center" >            
            <div class="col-md-12">
              <router-link :to="{ name: 'Signin' }" class="btn btn-primary float-center">Aceptar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template> 

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
          this.title = 'Token inválido o expirado';
          this.buttontext = 'Aceptar';
    },
    methods: {
      setClose(close){
        this.close = close;
      },
    }
  }//FIN DE SCRIPT
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>